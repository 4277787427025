<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Organization List">
        <div class="row align--center">
          <div class="flex xs12 md6">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="Search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex xs12 md3 offset--md3">
            <va-button color="success" icon="fa fa-plus" v-if="(adminRole || createProvision)&& (!supportRole)" @click="add()">Add</va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          clickable
        >
          <template slot="status" slot-scope="props">
              <va-button flat small color="#a632a8" v-if="(!props.rowData.org_status) || (props.rowData.org_status == null)" @click="pendingSts(props.rowData)">Pending</va-button>
              <div class="ml-4">
                <img src="@/assets/success_icon.png" v-if="props.rowData.org_status" data-toggle="tooltip" title="Activated" style="width: 17px; height: 17px; margin-top: 2px; margin-left: 10px; cursor: pointer;" />
              </div>
          </template>
          <template slot="actions" slot-scope="props">
            <va-button flat small color="gray" icon="fa fa-pencil" v-if="(adminRole || createProvision)&& (!supportRole)" @click="edit(props.rowData)" class="ma-0"></va-button>
          </template>
        </va-data-table>
      </va-card>
    </div>
    <div v-else-if='isshowForm'>
      <div>
        <va-card :title="title">
          <template slot="actions">
            <va-button color="gray" @click="list()">List</va-button>
          </template>
          <div>
            <form>
              <va-modal
                v-model="showRemoveModal"
                title="Warning"
                size='small'
                :message="message"
                okText="Confirm"
                cancelText="Cancel"
                @ok="updateMso()"
                @cancel="cancel()">
              </va-modal>
              <span v-if="!adminRole" class="va-form-label va-form-required-label">Organization Type</span>
              <span v-if="adminRole" class="va-form-label">Organization Type</span>
              <va-select
                v-if="isCreate"
                placeholder="Select Organization Type"
                v-model="org_type"
                textBy="id"
                searchable
                :options="orgTypeObj"
                @input="getOrgData(org_type)"
                noClear
                required="true"
              />
              <va-input
                v-if="isUpdate"
                v-model="org_type"
                type="text"
                placeholder="Enter Organization Type"
                :error="!!orgTypeErrors.length"
                :error-messages="orgTypeErrors"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">Organization Name (Max length cannot exceed 49 characters)</span>
              <va-input
                v-if="isCreate && !orgFlag"
                v-model.trim="org_name"
                type="text"
                maxlength =49
                placeholder="Enter Organization Name"
                :error="!!orgNameErrors.length"
                :error-messages="orgNameErrors"
                required="true"
              />
              <va-input
                v-if="isCreate && orgFlag && !adminRole"
                v-model.trim="org_name"
                type="text"
                maxlength =49
                placeholder="Enter Organization Name"
                :error="!!orgNameErrors.length"
                :error-messages="orgNameErrors"
                required="true"
              />
              <va-select
                v-if="isCreate && orgFlag && adminRole"
                placeholder="Select Organization Name"
                v-model="org_name"
                textBy="org_name"
                :options="orgNameObj"
                :error="!!orgNameErrors.length"
                :error-messages="orgNameErrors"
                @input="getCustomerOrgData(org_name)"
                noClear
                required="true"
              />
              <va-input
                v-if="isUpdate"
                v-model="org_name"
                type="text"
                :disabled="true"
              />
              <span v-if="orgFlag && isCreate" class="va-form-label va-form-required-label">Reseller Org</span>
              <va-select
                v-if="orgFlag && isCreate"
                placeholder="Select Reseller Organization"
                v-model="reseller_org"
                textBy="org_name"
                :options="orgArr"
                :error="!!resellerOrgErrors.length"
                :error-messages="resellerOrgErrors"
                noClear
                required="true"
              />
              <span v-if="orgFlag && isUpdate" class="va-form-label">Reseller Organization Name </span>
              <va-input
                v-if="orgFlag && isUpdate"
                v-model="reseller_org_edit"
                type="text"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">Technical Email</span>
              <va-input
                v-model.trim="technical_email"
                type="text"
                placeholder="Enter Technical Email"
                pattern="^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{1,4}$"
                :error="!!technicalEmailErrors.length"
                :error-messages="technicalEmailErrors"
                required="true"
              />
              <span class="va-form-label va-form-required-label">Report Email</span>
              <va-input
                v-model.trim="report_email"
                type="text"
                placeholder="Enter Report Email"
                pattern="^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{1,4}$"
                :error="!!reportEmailErrors.length"
                :error-messages="reportEmailErrors"
                required="true"
              />
              <span class="va-form-label va-form-required-label">Phone Number</span>
              <va-input
                v-model.trim="phone_no"
                type="text"
                maxlength=10
                pattern="[6-9]{1}[0-9]{9}"
                placeholder="Enter Phone Number"
                :error="!!phoneNoErrors.length"
                :error-messages="phoneNoErrors"
                required="true"
              />
              <span class="va-form-label va-form-required-label">State</span>
              <va-select
                v-if="isCreate"
                placeholder="Select State"
                searchable
                v-model="state"
                textBy="name"
                :options="stateList"
                :error="!!stateErrors.length"
                :error-messages="stateErrors"
                @input="getCityByState(state)"
                required="true"
                noClear
              />
              <va-input
                v-if="isUpdate"
                v-model="state"
                type="text"
                :disabled="true"
              />
              <span v-if="((city_flag && isCreate) || isUpdate)" class="va-form-label va-form-required-label">City</span>
              <va-select
                v-if="city_flag && isCreate"
                v-model="city"
                type="text"
                textBy="name"
                searchable
                :options="city_list"
                placeholder="Select City"
                :error="!!cityErrors.length"
                required="true"
                :error-messages="cityErrors"
              />
              <va-input
                v-if="isUpdate"
                v-model="city"
                type="text"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">Short Code</span>
              <va-input
                v-model.trim="short_code"
                type="text"
                placeholder="Enter Short Code"
                :error="!!shortCodeErrors.length"
                :error-messages="shortCodeErrors"
                required="true"
              />
              <span class="va-form-label va-form-required-label">Pincode</span>
              <va-input
                v-if="isCreate"
                v-model.trim="pincode"
                type="text"
                maxlength=6
                pattern="[1-9][0-9]{5}"
                placeholder="Enter Pincode"
                :error="!!pincodeErrors.length"
                :error-messages="pincodeErrors"
                required="true"
              />
              <va-input
                v-if="isUpdate"
                v-model="pincode"
                type="text"
                :disabled="true"
              />
              <span class="va-form-label">Gst Number</span>
              <va-input
                v-model.trim="gst_number"
                type="text"
                placeholder="Enter GST Number"
                :error="!!gstNumberErrors.length"
                :error-messages="gstNumberErrors"
              />
              <va-checkbox
                v-model="status"
                label="Status"
              />
              <va-checkbox
                v-if="adminFlag && sub_op_flag"
                v-model="sub_operators"
                label="Allow Sub Operator creation"
              />
              <va-checkbox
                v-model="enable_iptv"
                label="Enable IPTV"
                @input="enableIptv()"
              />
              <div v-if="enable_iptv">
                <span class="va-form-required-label va-form-label">MSO Provider</span>
                <va-select
  		            v-if="isCreate || isUpdate"
                  placeholder="Select Provider"
                  v-model="mso_provider"
                  textBy="brand"
                  @input="getFilter(mso_provider)"
                  :options="providerArr"
                  :error="!!ProviderErrors.length"
                  :error-messages="ProviderErrors"
                />
              </div>
              <va-checkbox
                v-if="adminFlag"
                v-model="ad_provision"
                label="Ad Provision"
              />
              <va-checkbox
                v-if="orgFlag"
                v-model="ott"
                label="OTT"
              />
              <va-checkbox
                v-if=false
                v-model="iptv"
                label="IPTV"
              />
              <va-checkbox
                v-if="adminFlag && msoFlag"
                v-model="mso"
                label="MSO"
              />
              <div class="mt-1" v-if="providerTypeFlag">
                <div v-if="providerFlag && !orgFlag && enable_iptv">
                  <span class="va-form-label va-form-required-label">Provider Type</span>
                  <fieldset class="row" style="margin-left: 1px;">
                    <va-radio-button
                      option="Independent"
                      v-model="provider_type"
                      label="Independent"
                    />
                    <va-radio-button
                      option="Partner"
                      v-model="provider_type"
                      label="Partner"
                    />
                  </fieldset>                
                </div>
                <div v-if="!providerFlag && !orgFlag && enable_iptv">
                  <span class="va-form-label va-form-required-label">Provider Type</span>
                  <fieldset class="row" style="margin-left: 1px;">
                    <va-radio-button
                      option="Independent"
                      v-model="provider_type"
                      label="Independent"
                      disabled
                    />
                    <va-radio-button
                      option="Partner"
                      v-model="provider_type"
                      label="Partner"
                      disabled
                    />
                  </fieldset>                
                </div>
              </div>
              <div class="mt-3">
                <span class="va-form-label">Access Type</span>
                <va-checkbox
                  v-model="access_type_mobile"
                  label="Mobile"
                />
                <va-checkbox
                  v-model="access_type_tv"
                  label="TV"
                />
                <va-checkbox
                  v-model="access_type_stb"
                  label="STB"
                />
                <va-checkbox
                  v-if="access_type_stb"
                  v-model="allowed_app"
                  label="Allowed Apps"
                />
              </div>
              <va-checkbox
                v-if="adminRole"
                v-model="forced_reseller"
                label="Sync Sub Organization"
              />

              <div class="d-flex justify--end mt-3">
                <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
                <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createOrg()">Create</va-button>
                <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateOrg()">Update</va-button>
              </div>
            </form>
          </div>
        </va-card>
      </div>
    </div>
    <va-modal
      v-model="isStatus"
      class="flex md12"
      large
      title="Organization Status"
      cancelText=""
      @ok.prevent="list()"
      noOutsideDismiss
      noEscDismiss
    > 
      <div class="va-table" v-if="!pend_status_flag">
        <table style="width: 100%;" class="va-table-responsive">
          <thead>
            <tr>
              <th class="content-th" width="50%">Process</th>
              <th class="content-th" width="50%">Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!user_status">
              <td>User</td>
              <td><va-button data-toggle="tooltip" @click.prevent="callpage('user')" title="Not Activated!, Click to Activate it" flat medium color="red" icon="fa fa-exclamation-circle" class="ma-0 cursor"></va-button></td>
            </tr>
            <tr v-if="!oper_status">
              <td>Operator Setting</td>
              <td><va-button data-toggle="tooltip" @click.prevent="callpage('operator_setting')" title="Not Activated!, Click to Activate it" flat medium color="red" icon="fa fa-exclamation-circle" class="ma-0 cursor"></va-button></td>
            </tr>
            <tr v-if="!sprt_status && !reseller_role">
              <td>Support Setting</td>
              <td><va-button data-toggle="tooltip" @click.prevent="callpage('support_setting')" title="Not Activated!, Click to Activate it" flat medium color="orange" icon="fa fa-exclamation-circle" class="ma-0 cursor"></va-button></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="pend_status_flag">
        <center>{{status_msg}}</center>
      </div>
    </va-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
import REGEX from '../../i18n/regex_pattern'

Vue.use(vueResource)
export default {
  name: 'org',
  beforeCreate () {
    var role = this.$cookies.get('userRole'), vm = this;
    this.$http.get(config.menu.host + '/customer_org').then(resp => {
      this.orgNameObj = resp.body
      this.orgNameObj.map(function(prop, i){
        prop.id = i + 1
      })
      this.$http.get(config.menu.host + '/org/stateCity').then(re => {
        this.state_city_mapper = re.body
        this.state_city_mapper.map(function (item, i) {
          item.id = i + 1
        })
        this.stateList = this.state_city_mapper
          this.$http.get(config.menu.host + '/provider').then(resp => {
          resp.body = resp.body.filter(function(prop,i){
            prop['id']=i+1
            return prop.iptv
          })
          this.providerArr = resp.body
          this.allProviderArr = resp.body
        })
        this.getAllOrg ()
      })
    })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      adminRole: true,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      message: '',
      set_moq: false,
      free_credit: false,
      enable_vod: false,
      access_type_mobile: false,
      access_type_tv: false,
      access_type_stb: false,
      allowed_app: false,
      ad_provision: false,
      status: false,
      city_flag: false,
      sub_operators: false,
      orgFlag: false,
      adminFlag: false,
      createProvision: false,
      org_name: '',
      reseller_org: '',
      org_type: '',
      technical_email: '',
      report_email: '',
      phone_no: '',
      state: '',
      city: '',
      pincode: '',
      short_code: '',
      gst_number: '',
      title: '',
      orgData: [],
      orgArr: [],
      orgNameErrors: [],
      resellerOrgErrors: [],
      orgTypeErrors: [],
      shortCodeErrors: [],
      technicalEmailErrors: [],
      reportEmailErrors: [],
      phoneNoErrors: [],
      cityErrors: [],
      stateErrors: [],
      pincodeErrors: [],
      gstNumberErrors: [],
      orgTypeObj: [],
      stateList: [],
      iptv: false,
      ott: false,
      enable_iptv: false,
      iptv_update_status: false,
      iptv_on_update: false,
      iptv_mso_update: false,
      mso_provider: '',
      old_mso_provider: '',
      providerArr: [],
      allProviderArr: [],
      ProviderErrors: [],
      orgNameObj: [],
      sub_op_flag: false,
      city_list: [],
      msoFlag: false,
      mso: false,
      providerFlag: false,
      provider_type: '',
      providerTypeFlag: false,
      isStatus: false,
      user_status: '',
      oper_status: '',
      sprt_status: '',
      pend_status_flag: false,
      status_msg: '',
      state_city_mapper: [],
      reseller_role : false,
      reseller_org_edit: '',
      forced_reseller: false,
      provider_id: '',
supportRole:false
    }
  },
  computed: {
    formReady () {
      return !this.orgNameErrors.length &&
             !this.technicalEmailErrors.length &&
             !this.reportEmailErrors.length &&
             !this.phoneNoErrors.length &&
             !this.stateErrors.length &&
             !this.cityErrors.length &&
             !this.pincodeErrors.length &&
             !this.shortCodeErrors.length
      if (this.enable_iptv == true) {
        return !this.ProviderErrors.length
      }
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: 'org_name',
        title: 'Organization Name',
      },
      {
        name: 'org_type',
        title: 'Organization Type',
      },
      {
        name: 'phone_no',
        title: 'Phone Number',
      },
      {
        name: '__slot:status',
        title: 'Activated status',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.orgData)
    },
  },
  methods: {
    getCustomerOrgData (data) {
      this.technical_email = data.technical_email
      this.report_email = data.report_email
      this.gst_number = data.gst_number
      this.phone_no = data.phone_no
      this.state = data.state
      this.city = data.city
      this.short_code = data.short_code
      this.pincode = data.pincode
      this.city_flag = true
    },
    getOrgData (org_type) {
      //this.reset()
      this.sub_op_flag = false
      if (org_type.id === 'OPERATOR' || org_type.id === 'HEPI_OPERATOR') {
        this.orgFlag = true
        this.$http.get(config.menu.host + '/org').then(resp => {
          this.orgArr = resp.body
          this.orgArr.map(function (arg, i) { arg.id = i + 1 }) 
          this.orgArr = this.orgArr.filter(function (prop) {
            return (prop.org_type === 'RESELLER') || (prop.org_type === 'ADMIN') || (prop.org_type == 'HEPI_RESELLER')
          })
        }, error => {
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
      } else {
        if(org_type.id === 'RESELLER'  || org_type.id == 'HEPI_RESELLER') {
         this.sub_op_flag = true
         this.msoFlag = true
        }
        this.orgFlag = false
      }
    },
    pendingSts (data){
      this.reseller_role = (data.org_type == 'RESELLER' || data.org_type == 'RESELLER') ? true : false
      this.pend_status_flag = false
      var flagone = false
      this.$http.get(config.menu.host + '/org/pending/' + data.org_id).then(dataResp => {
        this.user_status = dataResp.body.user_status
        this.oper_status = dataResp.body.oper_status
        this.sprt_status = dataResp.body.sprt_status
        if(this.user_status == 1 && this.oper_status == 1 && this.sprt_status == 1 && (data.org_type == 'OPERATOR' || data.org_type == 'HEPI_OPERATOR' )){
          this.pend_status_flag = true
          flagone = true
          this.status_msg = "No process is pending, You have completed it successfully"
        }else if(this.user_status == 1 && this.oper_status == 1 && (data.org_type != 'OPERATOR' || data.org_type != 'HEPI_OPERATOR')){
          this.pend_status_flag = true
          flagone = true
          this.status_msg = "No process is pending, You have completed it successfully"
        }else{
          this.pend_status_flag = false 
        }
        if(flagone){
          var payload = { org_status : true }
          const loader = Vue.$loading.show({ width: 40, height: 40 })
          this.$http.put(config.menu.host + '/org/' + data.org_id, payload).then(resp => {
            loader.hide()
            this.isStatus = false
            this.list()
            if (resp && resp.body) {
              Vue.notify({ text: 'Activation Completed', type: 'success' })
            }
          })
        }else{
          this.isStatus = true
        }
      })
      return
    },
    callpage (type){
      this.$router.push({ name: type })
    },
    getAllOrg () {
      var vm = this
      var role = this.$cookies.get('userRole')
      if ((role === 'ADMIN')) {
        this.adminRole = true
        this.createProvision = true
      } else {
        this.adminRole = false
      }
      var ref = this
      if(role=='SUPPORT'){
        this.supportRole=true;
      }
      this.$http.get(config.menu.host + '/org').then(response => {
        response.body.map(function (thing) {
          if (thing.org_type === 'RESELLER'  || thing.org_type === 'HEPI_RESELLER') {
            ref.createProvision = thing.sub_operators
          }
        })
        let index = 0
        this.orgData = []
        response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          if(!vm.adminRole && !vm.supportRole && (item.org_type == 'OPERATOR' || item.org_type == 'HEPI_OPERATOR')){
            vm.orgData.push(item)
          }else if(vm.adminRole || vm.supportRole){
            vm.orgData.push(item)
          }
        })
        this.isshowForm = false
        this.isshowgrid = true
      })
    },
    enableIptv (enable_iptv) {
      var org_type = this.isCreate ? this.org_type.id : this.org_type
      if(!this.reseller_org && (org_type == 'OPERATOR' || org_type == 'HEPI_OPERATOR')){
        this.enable_iptv = false;
        return Vue.notify({ text: 'Please select reseller', type: 'error'})
      }else{
        if (enable_iptv == true) {
          this.access_type_stb = true
        } else if (enable_iptv == false) {
          this.access_type_stb = false
        }
        if (org_type == 'OPERATOR' || org_type == 'HEPI_OPERATOR') {
          var reseller_data = this.reseller_org.mso_provider
          if(reseller_data){
            this.providerArr = this.providerArr.filter(function (argument) {
              return argument.brand == reseller_data
            })
          }else{
            this.enable_iptv = false;
            return Vue.notify({ text: 'IPTV is not enabled by Reseller. Please contact them', type: 'error' })
          }
          
        }
      }
    },
    cancel () {
      this.enable_iptv = true
      this.showRemoveModal = false
    },
    createOrg () {
      var organization = (this.org_name.org_name) ? this.org_name.org_name : this.org_name
      this.orgNameErrors = organization ? [] : ['Organization Name is required']
      // this.orgTypeErrors = this.org_type ? [] : ['Organization Type is required']
      this.technicalEmailErrors = this.technical_email ? [] : ['Technical Email is required']
      this.reportEmailErrors = this.report_email ? [] : ['Report Email is required']
      this.phoneNoErrors = this.phone_no ? [] : ['Phone Number is required']
      this.stateErrors = this.state ? [] : ['State is required']
      this.cityErrors = this.city ? [] : ['City is required']
      this.pincodeErrors = this.pincode ? [] : ['Pincode is required']
      this.shortCodeErrors = this.short_code ? [] : ['Short Code is required']

      var role = this.$cookies.get('userRole')
      if (role == 'RESELLER' || role == 'HEPI_RESELLER') {
        if (this.org_type == '') {
          return Vue.notify({ text: 'Please Select the Org Type', type: 'error' })
        }
        if (this.reseller_org == '') {
          return Vue.notify({ text: 'Please Select the Reseller Org', type: 'error' })
        }
      }

      if (this.enable_iptv == true) {
        this.ProviderErrors = this.mso_provider ? [] : ['Provider is required']
        if(this.provider_type == '' && this.org_type.id == 'RESELLER'){
          return Vue.notify({ text: 'Please select the provider type', type: 'error'})
        }
      }

      if (!this.formReady) {
        return
      }
      if (!REGEX.OrgName(organization)) {
        return Vue.notify({ text: 'Org Name is Not valid', type: 'error' })
      }
      if (!REGEX.OnlyEmail(this.technical_email)) {
        return this.technicalEmailErrors.push('Technical Email is not valid!')
      }
      if (!REGEX.OnlyEmail(this.report_email)) {
        return this.reportEmailErrors.push('Report Email is not valid!')
      }
      if(!this.access_type_mobile && !this.access_type_tv && !this.access_type_stb){
        return Vue.notify({text:'Select atleast one access type',type:'error'})
      }

      var payload = {
        org_name: organization,
        org_type: this.org_type.id,
        technical_email: this.technical_email,
        report_email: this.report_email,
        phone_no: this.phone_no,
        state: (this.state.name != null) ? this.state.name : this.state,
        city: (this.city.name != null) ? this.city.name : this.city,
        pincode: this.pincode,
        gst_number: this.gst_number,
        status: this.status,
        ad_provision: this.ad_provision,
        free_credit: this.free_credit,
        enable_vod: this.enable_vod,
        access_type_mobile: this.access_type_mobile,
        access_type_tv: this.access_type_tv,
        access_type_stb: this.access_type_stb,
        allowed_app: this.allowed_app,
        set_moq: this.set_moq,
        sub_operators: this.sub_operators,
        short_code: this.short_code,
        ott: this.ott,
        iptv: this.iptv,
        enable_iptv: this.enable_iptv,
        mso_provider: this.mso_provider.brand,
        provider_id: this.mso_provider.provider_id,
        mso: this.mso,
        provider_type: this.provider_type,
        forced_reseller: this.forced_reseller
      }
      if (payload.org_type === 'OPERATOR' || payload.org_type === 'HEPI_OPERATOR') {
        if(this.enable_iptv){
          payload.provider_type=this.reseller_org.provider_type
        }
        payload.reseller_org_id = this.reseller_org.reseller_org_id
      }
      
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/org', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.getAllOrg()
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateMso () {
      if (this.mso_provider.brand == this.old_mso_provider) {
        this.iptv_mso_update = false
      } else {
        this.iptv_mso_update = true
      }
      this.updateNow()
    },
    updateOrg () {
      this.technicalEmailErrors = this.technical_email ? [] : ['Technical Email is required']
      this.reportEmailErrors = this.report_email ? [] : ['Report Email is required']
      this.phoneNoErrors = this.phone_no ? [] : ['Phone Number is required']
      this.pincodeErrors = this.pincode ? [] : ['Pincode is required']
      this.shortCodeErrors = this.short_code ? [] : ['Short Code is required']
      if (!this.formReady) {
        return
      }
      if (this.enable_iptv && this.mso_provider == '') {
        return Vue.notify({text:'Please select the mso provider',type:'error'})
      }
      if (!REGEX.OnlyEmail(this.report_email)) {
        return this.reportEmailErrors.push('Report Email is not valid!')
      }
      if(!this.access_type_mobile && !this.access_type_tv && !this.access_type_stb){
        return Vue.notify({text:'Select atleast one access type',type:'error'})
      }
      if(this.provider_type && this.org_type == 'RESELLER' && this.$cookies.get('userRole') == 'ADMIN'){
        var vm = this,flag = false;
        this.providerArr.map(function(data){
          if(data.brand == vm.mso_provider){
            if((data.provider_type !== vm.provider_type) && data.provider_type === 'Independent'){
              return flag = true
            }
          }
        })
        if(flag){
          return Vue.notify({text: 'MSO provider type is difference from yours provider type', type: 'error'})
        }
      }
      if (this.mso_provider == this.old_mso_provider) {
        this.iptv_mso_update = false
      } else {
        this.iptv_mso_update = true
      }
      if (this.iptv_update_status) {
        if (this.enable_iptv) {
          this.iptv_update_status = false
        } else {
          this.iptv_update_status = true
        }
      } else {
        if (this.enable_iptv) {
          this.iptv_on_update = true
          this.iptv_mso_update = false
        }
      }
      if ((this.iptv_update_status || this.iptv_mso_update) && !this.showRemoveModal) {
        this.title = 'Updation of IPTV'
        this.message = 'Your existing IPTV will be deactivated. Are you sure to disable the IPTV ?'
        this.showRemoveModal = true
      } else {
        this.updateNow()
      }
    },
    updateNow () {
      var payload = {
        org_name: this.org_name,
        org_type: this.org_type,
        technical_email: this.technical_email,
        report_email: this.report_email,
        phone_no: this.phone_no,
        state: this.state.name,
        city: this.city.name,
        pincode: this.pincode,
        gst_number: this.gst_number,
        status: this.status,
        ad_provision: this.ad_provision,
        free_credit: this.free_credit,
        set_moq: this.set_moq,
        sub_operators: this.sub_operators,
        enable_vod: this.enable_vod,
        access_type_mobile: this.access_type_mobile,
        access_type_tv: this.access_type_tv,
        access_type_stb: this.access_type_stb,
        allowed_app: this.allowed_app,
        short_code: this.short_code,
        ott: this.ott,
        iptv: this.iptv,
        enable_iptv: this.enable_iptv,
        iptv_update_status: this.iptv_update_status,
        iptv_on_update: this.iptv_on_update,
        iptv_mso_update: this.iptv_mso_update,
        mso_provider: this.mso_provider.brand ? this.mso_provider.brand : this.mso_provider,
        mso: this.mso,
        provider_type: this.provider_type,
        forced_reseller: this.forced_reseller,
        provider_id: (typeof(this.mso_provider) != 'object') ? this.provider_id : this.mso_provider.provider_id
      }
      if (payload.org_type === 'OPERATOR' || payload.org_type === 'HEPI_OPERATOR') {
        var vm=this;
        if(this.enable_iptv){
          this.orgArr.map(function(data){
            if((data.reseller_org_id==vm.reseller_org_id) && (data.org_type == 'RESELLER' || data.org_type == 'HEPI_RESELLER')){
              payload.provider_type=data.provider_type;
            }
          })
        }
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/org/' + this.org_id, payload).then(resp => {
        loader.hide()
        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.getAllOrg()
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    getCityByState (state) {
      var vm = this
      vm.city = ''
      if (state.cities) {
        vm.city_flag = false
        state.cities.map(function (prop, i) {
          prop.id = i + 1
        })
        vm.city_list = state.cities
        vm.city_flag = true
      } else {
        vm.city_flag = false
      }
    },
    getFilter (data) {
      let index = 0
      this.currentProvider = data
      this.currentProviderId = data.provider_id
      this.existing = []
      if (data.provider_type == 'Hybrid') {
        this.providerTypeFlag = true
        this.providerFlag = true
        this.provider_type = ''
      }else if(data.provider_type == 'Independent'){
        this.providerTypeFlag = true
        this.providerFlag = false
        this.provider_type = 'Independent'
      }else if(data == ''){
        this.providerTypeFlag = false
      }
      var vm = this;
      this.$http.get(config.menu.host + '/iptv/'+data.provider_id).then(resp => {
        this.genresData = resp.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.checkbox_value = item.status
          if(item.status){
            vm.existing.push(item)
          }
          return item
        })
      })
    },
    add () {
      var role = this.$cookies.get('userRole')
      if (role == 'ADMIN') {
        this.adminFlag = true
        this.orgTypeObj = [{ id: 'RESELLER' }, { id: 'OPERATOR' }, {id: 'HEPI_OPERATOR'}, {id: 'HEPI_RESELLER'}]
      } else if (role === 'RESELLER') {
        this.orgTypeObj = [{ id: 'OPERATOR' }]
      } else if(role === 'HEPI_RESELLER') {
        this.orgTypeObj = [{ id: 'HEPI_OPERATOR' }]
      }
      this.title = 'Create Organization'
      this.org_name = ''
      this.org_type = ''
      this.technical_email = ''
      this.report_email = ''
      this.phone_no = ''
      this.state = ''
      this.city = ''
      this.short_code = ''
      this.pincode = ''
      this.gst_number = ''
      this.status = true
      this.set_moq = false
      this.free_credit = false
      this.sub_operators = false
      this.enable_vod = false
      this.access_type_mobile = false,
      this.access_type_tv = false,
      this.access_type_stb = false,
      this.ad_provision = false
      this.allowed_app = false
      this.enable_iptv = false
      this.mso_provider = ''
      this.ProviderErrors = []
      this.ott = false
      this.iptv = false
      this.orgFlag = false
      this.shortCodeErrors = []
      this.orgNameErrors = []
      this.orgTypeErrors = []
      this.technicalEmailErrors = []
      this.reportEmailErrors = []
      this.phoneNoErrors = []
      this.stateErrors = []
      this.cityErrors = []
      this.pincodeErrors = []
      this.gstNumberErrors = []
      this.cityErrors = []
      this.reseller_org = ''
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
      this.provider_type = ''
      this.forced_reseller = false
    },
    edit (row) {
      var role = this.$cookies.get('userRole')
      if (role === 'ADMIN') {
        this.adminFlag = true
        this.orgTypeObj = [{ id: 'RESELLER' }, { id: 'OPERATOR' }, {id: 'HEPI_RESELLER'}, {id: 'HEPI_OPERATOR'}]
      } else if (role === 'RESELLER') {
        this.orgTypeObj = [{ id: 'OPERATOR' }]
      }else if(role === 'HEPI_RESELLER'){
        this.orgTypeObj = [{id : 'HEPI_OPERATOR'}]
      }
      this.sub_op_flag = (row.org_type == 'RESELLER' || row.org_type == 'HEPI_RESELLER')
      this.title = 'Update Organization'
      this.org_id = row.org_id
      this.org_name = row.org_name
      this.org_type = row.org_type
      if(this.org_type == 'OPERATOR'){
        this.getOrgData({id:'OPERATOR'}) 
      }else if(this.org_type == 'HEPI_OPERATOR'){
        this.getOrgData({id:'HEPI_OPERATOR'})
      }
      this.reseller_org_id=row.reseller_org_id;
      this.technical_email = row.technical_email
      this.report_email = row.report_email
      this.phone_no = row.phone_no
      this.state = row.state
      this.city = row.city
      this.short_code = row.short_code
      this.pincode = row.pincode
      this.gst_number = row.gst_number
      this.status = !!row.status
      this.set_moq = !!row.set_moq
      this.free_credit = !!row.free_credit
      this.sub_operators = !!row.sub_operators
      this.enable_vod = !!row.enable_vod
      this.access_type_mobile = !!row.access_type_mobile
      this.access_type_tv = !!row.access_type_tv
      this.access_type_stb = !!row.access_type_stb
      this.ad_provision = !!row.ad_provision
      this.allowed_app = !!row.allowed_app
      this.ott = row.ott ? row.ott : false
      this.iptv = row.iptv ? row.iptv : false
      this.enable_iptv = !!row.enable_iptv
      this.old_mso_provider = row.mso_provider ? row.mso_provider : ''
      this.mso_provider = row.mso_provider ? row.mso_provider : ''
      this.provider_id = row.provider_id
      this.iptv_update_status = !!row.enable_iptv
      this.orgFlag = (row.org_type == 'OPERATOR' || row.org_type == 'HEPI_OPERATOR')
      if (this.orgFlag) {
        this.$http.get(config.menu.host + '/org/orgData/' + row.reseller_org_id + '/' + 'reseller_org_id/RESELLER').then(resp => {
          this.reseller_org = resp.body
          this.reseller_org_edit = resp.body.org_name
          if (this.org_type == 'OPERATOR') {
            this.enableIptv();
          }
        })
      }
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
      this.provider_type = row.provider_type
      this.providerTypeFlag = (row.provider_type == null) ? false : true,
      this.forced_reseller = row.forced_reseller
      this.providerFlag = (row.org_type === 'RESELLER' && role === 'ADMIN') ? true : false
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllOrg()
      this.orgNameErrors = []
      this.technicalEmailErrors = []
      this.reportEmailErrors = []
      this.phoneNoErrors = []
      this.shortCodeErrors = []
      this.providerArr = this.allProviderArr
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
